import { createRouter, createWebHashHistory } from 'vue-router'
import AppLayout from '@/layout/AppLayout.vue';


const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      redirect: '/care-receiver/dashboard'
    },
    {
      path: '/care-receiver/dashboard',
      component: AppLayout,
      children: [
        {
            path: 'care-receiver/auth',
            name: 'auth',
            component: () => import('@/views/PatientAuth.vue'),
            meta: { title: 'Patient' }
        },
        {
            path: '/care-receiver/dashboard',
            name: 'cr dashboard',
            component: () => import('@/views/CareReceiverDashboard.vue'),
            meta: { title: 'Care Receiver Dashboard' }
        },
        {
            path: '/care-receiver/login',
            name: 'cr login',
            component: () => import('@/views/Login.vue'),
            meta: { title: 'Care Receiver Login' }
        },
        {
            path: '/care-receiver/registration',
            name: 'cr registration',
            component: () => import('@/views/Registration.vue'),
            meta: { title: 'Care Receiver Registration' }
        },
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'notfound',
      component: () => import('@/views/pages/NotFound.vue')
    },
  ]
})

// Navigation Guard
router.afterEach(async (to, from) => {
})

export default router
