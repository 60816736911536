import { createApp } from 'vue'
import { createPinia } from 'pinia'


import App from './App.vue'
import router from './router'

import MenuIcon from 'vue-material-design-icons/Menu.vue';
import WeightLifter from 'vue-material-design-icons/WeightLifter.vue';
import HeartPulse from 'vue-material-design-icons/HeartPulse.vue';
import HeadSnowflakeOutline from 'vue-material-design-icons/HeadSnowflakeOutline.vue';
import FormatListBulletedSquare from 'vue-material-design-icons/FormatListBulletedSquare.vue';
import PillMultiple from 'vue-material-design-icons/PillMultiple.vue';
import EmoticonSickOutline from 'vue-material-design-icons/EmoticonSickOutline.vue';
import CheckDecagramOutline from 'vue-material-design-icons/CheckDecagramOutline.vue';
import PlayCircle from 'vue-material-design-icons/PlayCircle.vue';
import AccountQuestionOutline from 'vue-material-design-icons/AccountQuestionOutline.vue';
import ProgressStar from 'vue-material-design-icons/ProgressStar.vue';
import ProgressClock from 'vue-material-design-icons/ProgressClock.vue';
import PagePreviousOutline from 'vue-material-design-icons/PagePreviousOutline.vue';
import PageNextOutline from 'vue-material-design-icons/PageNextOutline.vue';
import VirtualReality from 'vue-material-design-icons/VirtualReality.vue';
import VideoPlus from 'vue-material-design-icons/VideoPlus.vue';
import Yoga from 'vue-material-design-icons/Yoga.vue';
import Meditation from 'vue-material-design-icons/Meditation.vue';
import SunClockOutline from 'vue-material-design-icons/SunClockOutline.vue';
import MessageText from 'vue-material-design-icons/MessageText.vue';
import Forum from 'vue-material-design-icons/Forum.vue';
import GenderTransgender from 'vue-material-design-icons/GenderTransgender.vue';
import GenderMale from 'vue-material-design-icons/GenderMale.vue';
import GenderFemale  from 'vue-material-design-icons/GenderFemale.vue';
import WaterCheck  from 'vue-material-design-icons/WaterCheck.vue';
import Controller  from 'vue-material-design-icons/Controller.vue';


import PrimeVue from 'primevue/config'
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Menu from 'primevue/menu';
import OverlayPanel from 'primevue/overlaypanel';
import Password from 'primevue/password';
import ProgressBar from 'primevue/progressbar';
import Ripple from 'primevue/ripple';
import ScrollPanel from 'primevue/scrollpanel';
import SelectButton from 'primevue/selectbutton';
import StyleClass from 'primevue/styleclass';
import Tag from 'primevue/tag';
import Textarea from 'primevue/textarea';
import Timeline from 'primevue/timeline';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';


import '@/assets/styles.scss';

const app = createApp(App)

app.component('menu-icon', MenuIcon);
app.component('weight-lifter-icon', WeightLifter);
app.component('heart-pulse-icon', HeartPulse);
app.component('head-snowflake-outline-icon', HeadSnowflakeOutline);
app.component('format-list-bulleted-square-icon', FormatListBulletedSquare);
app.component('pill-multiple', PillMultiple);
app.component('emoticon-sick-outline', EmoticonSickOutline);
app.component('check-decagram-outline', CheckDecagramOutline);
app.component('play-circle', PlayCircle);
app.component('account-question-outline', AccountQuestionOutline);
app.component('progress-star', ProgressStar);
app.component('progress-clock', ProgressClock);
app.component('page-previous-outline', PagePreviousOutline);
app.component('page-next-outline', PageNextOutline);
app.component('virtual-reality', VirtualReality);
app.component('video-plus', VideoPlus);
app.component('yoga-icon', Yoga);
app.component('meditation-icon', Meditation);
app.component('sun-clock-outline', SunClockOutline);
app.component('message-text-icon', MessageText);
app.component('forum-icon', Forum);
app.component('gender-transgender', GenderTransgender);
app.component('gender-male', GenderMale);
app.component('gender-female', GenderFemale);
app.component('water-check-icon', WaterCheck);
app.component('controller-icon', Controller);


app.use(PrimeVue, {ripple: true})
app.use(createPinia())
app.use(ToastService);
app.use(router)

app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);

app.component('Badge', Badge);
app.directive('badge', BadgeDirective);
app.component('Button', Button);
app.component('Card', Card);
app.component('Carousel', Carousel);
app.component('Chart', Chart);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Chips', Chips);
app.component('Column', Column);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('DataTable', DataTable);
app.component('Dropdown', Dropdown);
app.component('FileUpload', FileUpload);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Menu', Menu);
app.component('OverlayPanel', OverlayPanel);
app.component('Password', Password);
app.component('ProgressBar', ProgressBar);
app.component('ScrollPanel', ScrollPanel);
app.component('SelectButton', SelectButton);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('Timeline', Timeline);
app.component('Toast', Toast);
app.component('ToggleButton', ToggleButton);
app.directive('tooltip', Tooltip);




app.mount('#app')
